import React, { useState } from "react"
import NavBarStyles from "./styles/NavBar.scss"

const NavBar = () => {
	return (
		<header className={NavBarStyles.header}>
			<nav>
				<a href="/" className={NavBarStyles.header__logo}>
					<svg className={NavBarStyles.logo} viewBox="0 0 230 85">
						<line x1="4" y1="75.5" x2="47" y2="75.5" stroke="#000000" strokeWidth="3" strokeLinecap="square"></line>
						<line x1="-36.5" y1="38" x2="37.5" y2="38" stroke="#000000" strokeWidth="5" strokeLinecap="square" transform="translate(1.000000, 38.000000) rotate(90.000000) translate(-1.000000, -38.000000) "></line>
						<line x1="188.5" y1="38" x2="263.5" y2="38" stroke="#000000" strokeWidth="3" strokeLinecap="square" transform="translate(226.000000, 38.000000) rotate(90.000000) translate(-226.000000, -38.000000) "></line>
						<line x1="1" y1="0.5" x2="224" y2="0.5" stroke="#000000" strokeWidth="5" strokeLinecap="square"></line>
						<line x1="174" y1="75.5" x2="226" y2="75.5" stroke="#000000" strokeWidth="3" strokeLinecap="square"></line>
						<text fontFamily="KannadaMN, Kannada MN" fontSize="60" fontWeight="normal" fill="#000000">
								<tspan x="22.5" y="57">REYES</tspan>
						</text>
						<text fontFamily="Georgia" fontSize="14" fontWeight="normal" fill="#000000">
								<tspan x="54.5" y="80">CONSTRUCTION</tspan>
						</text>
					</svg>
				</a>
			</nav>
			<div>
				<a href="mailto:reyescontruction@yahoo.com">
					<svg viewBox="0 0 32 32" className={NavBarStyles.mail}>
						<path d="M30 4h-28c-1.1 0-2 0.9-2 2v20c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-20c0-1.1-0.9-2-2-2zM28 8v1.438l-12 7.071-12-7.071v-1.438h24zM4 24v-11.080l12 7.071 12-7.071v11.080h-24z" />
					</svg>
				</a>
				<a href="tel:8477787252">
					<svg viewBox="0 0 32 32" className={NavBarStyles.phone}>
						<path d="M22 20c-2 2-2 4-4 4s-4-2-6-4-4-4-4-6 2-2 4-4-4-8-6-8-6 6-6 6c0 4 4.109 12.109 8 16s12 8 16 8c0 0 6-4 6-6s-6-8-8-6z" />
					</svg>
				</a>
			</div>
		</header>
	)
}

export default NavBar
