import React from "react"
import OwnerStyles from "./styles/Owner.scss"
// import ownerImage from "images/owner.jpg"

function Owner({ view, setView }) {
	return (
		<div className={`${OwnerStyles.container} ${view === "home" ? OwnerStyles.hide : ""}`} onClick={() => setView("home")}>
			<div className={OwnerStyles.top}>
				<p className={OwnerStyles.cta}>
					Creating your ideal oasis at home is a dream that's attainable with Reyes.
				</p>
			</div>

			<div className={OwnerStyles.bottom}>
				{/* <div className={OwnerStyles.leftContainer}>
					<img src={ownerImage} />
				</div> */}

				<div className={OwnerStyles.title}>
					<h4>Rick Adame</h4>
					<h6>Owner</h6>
				</div>
				<div className={OwnerStyles.arrowContainer}>
					<svg viewBox="0 0 53 72">
						<g fill="none" fillRule="evenodd" strokeLinecap="round" stroke="#fff" strokeWidth="5">
							<line x1="4.24682705" y1="65.5345824" x2="42.5275067" y2="32.5493304" id="Line-2" transform="rotate(0.682524)"></line>
							<line x1="1.81818934" y1="3.2237449" x2="44.9561444" y2="29.5401678" id="Line-1" transform="rotate(8.682524)"></line>
						</g>
					</svg>
				</div>
			</div>
		</div>
	)
}

export default Owner
