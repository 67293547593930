import React from "react"
import RenovationStyles from "./styles/Renovation.scss"

const Renovation = ({ setView, view }) => {
	return (
		<div className={`${RenovationStyles.container} ${view === "renovation" ? RenovationStyles.open : ""}`}  onClick={() => setView("renovation")}>
			<div className={RenovationStyles.bottom}>
				<div className={RenovationStyles.title}>
					<h4>Renovation</h4>
				</div>
				<div className={RenovationStyles.arrowContainer}>
					<svg viewBox="0 0 53 72">
						<g fill="none" fillRule="evenodd" strokeLinecap="round" stroke="#ddd" strokeWidth="5">
							<line x1="4.24682705" y1="65.5345824" x2="42.5275067" y2="32.5493304" id="Line-2" transform="rotate(0.682524)"></line>
							<line x1="1.81818934" y1="3.2237449" x2="44.9561444" y2="29.5401678" id="Line-1" transform="rotate(8.682524)"></line>
						</g>
					</svg>
				</div>
			</div>
		</div>
	)
}

export default Renovation
