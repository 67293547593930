import React from 'react'
import RealEstateStyles from "./styles/RealEstate.scss"

const RealEstate = ({ setView, view }) => {
	return (
		<div className={`${RealEstateStyles.container} ${view === "realEstate" ? RealEstateStyles.active : ""}`} onClick={() => setView("realEstate")}>
			{/* <div className={RealEstateStyles.top}>
				Some content
			</div> */}
			<div className={RealEstateStyles.bottom}>
				<div className={RealEstateStyles.title}>
					<h4>Real Estate</h4>
				</div>
				<div className={RealEstateStyles.arrowContainer}>
					<svg viewBox="0 0 53 72">
						<g fill="none" fillRule="evenodd" strokeLinecap="round" stroke="#000" strokeWidth="5">
							<line x1="4.24682705" y1="65.5345824" x2="42.5275067" y2="32.5493304" id="Line-2" transform="rotate(0.682524)"></line>
							<line x1="1.81818934" y1="3.2237449" x2="44.9561444" y2="29.5401678" id="Line-1" transform="rotate(8.682524) "></line>
						</g>
					</svg>
				</div>
			</div>
		</div>
	)
}

export default RealEstate
