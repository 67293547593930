import React, { useState, useEffect } from 'react'
import HeroStyles from "./styles/Hero.scss"

import Owner from "./Owner"
import RealEstate from "./RealEstate"
import Construction from "./Construction"
import Renovation from "./Renovation"

function Hero() {
	const [view, setView] = useState("")
	useEffect(() => {
		setTimeout(() => setView("home"), 100)
	}, [])
	return (
		<div className={`${HeroStyles.container}`}>
			<div className={HeroStyles.imageContainer}>
				<div className={`${HeroStyles.image} ${HeroStyles.renovation} ${view === "renovation" ? HeroStyles.active : ""}`} />
				<div className={`${HeroStyles.image} ${HeroStyles.construction} ${view === "construction" ? HeroStyles.active : ""}`} />
				<div className={`${HeroStyles.image} ${HeroStyles.realEstate} ${view === "realEstate" ? HeroStyles.active : ""}`} />
				<div className={`${HeroStyles.image} ${HeroStyles.hero} ${view === "home" ? HeroStyles.active : ""}`} />
			</div>

			<div className={HeroStyles.bottom}>
				<Owner view={view} setView={setView} />
				<RealEstate view={view} setView={setView} />
				<Construction view={view} setView={setView} />
				<Renovation view={view} setView={setView} />
			</div>
		</div>
	)
}

export default Hero
